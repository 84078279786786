import React, { useState } from "react";
import { StyledBox, StyledOrderBox } from "../../components/UI/StyleBox";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BackButton } from "../../components/UI/Button";
import { OrderCard } from "./MyOrderList";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoIosSearch } from "react-icons/io";
const BroadCast = ({ data=[] }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/dashboard");
  };

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOrders = data.filter((order) =>
    order.broadcast_no.includes(searchQuery)
  );


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setIsFocused(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleSearchChange(e); // Call handleSearchChange when input changes
  };

  const toggleFocus = () => {
    if (isFocused && inputValue === "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };
  console.log(filteredOrders, "data");
  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
          My Broadcast List
          </Typography>
        </Box>
        <Box>
          <form className={`search ${isFocused ? "focused" : ""}`}>
            <input
              type="number"
              className={`search__input ${isFocused ? "focused" : ""}`}
              placeholder="Search by Job No."
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="search__button"
              onClick={toggleFocus}
            >
              <IoIosSearch size={23} color="#FFFFFF" />
            </button>
          </form>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </StyledBox>
      <>
      <StyledOrderBox> 
        <Grid container spacing={3}>
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <OrderCard
                  order={order}
                  date={order.job_dt}
                  time={order.job_tm}
                  discription={order.job_detail}
                  Id={order.broadcast_id}
                  name={order.name}
                  jobNo={order.broadcast_no}
                  list={{
                    category: order?.category,
                    sub_category: order?.sub_category,
                  }}
                  totalAmount={order.job_budget}
                  status={order.status}
                  isBroadCast={true}
                  response={order.response}
                  broadcast={true}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  padding: 2,
                  color: "gray",
                }}
              >
              Your broadcast job list is empty
              </Typography>{" "}
            </Grid>
          )}
        </Grid>
        </StyledOrderBox>
      </>
    </>
  );
};

export default BroadCast;

// {
//   "job_detail": "csacascascascasc",
//   "job_location": "41, Bhamti, Mamati, Deendayal Nagar, Nagpur, Maharashtra 440022, India",
//   "job_lat": 21.112006125,
//   "job_long": 79.04665912499999,
//   "job_dt": "2024-07-17",
//   "job_tm": "14:09:00",
//   "job_budget": "140",
//   "status": "PENDING",
//   "is_skip": false,
//   "created_dt": "2024-07-16T10:54:52.177564",
//   "broadcast_no": "#989360",
//   "broadcast_id": 131,
//   "user_id": 100,
//   "category": {
//       "category_name": "Home Services",
//       "category_image": "https://api.suluhu.net/media/CategoryImages/unnamed.jpg"
//   },
//   "sub_category": {
//       "sub_category_name": "Home Insurance",
//       "sub_category_image": "https://api.suluhu.net/media/SubCategoryImages/655dd40aa1d11_download.jpg"
//   },
//   "response": 0
// }
