import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  Typography,
  CircularProgress,
} from "@mui/material";

const RatingDialog = ({ open, onClose, onSubmit, serviceName, isLoading }) => {
  const [rating, setRating] = useState(0);
  console.log(rating, "rating");
  const [isRated, setIsRated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRatingChange = (event, newValue) => {
    console.log(newValue, "newValue");
    if (newValue) {
      setRating(newValue);
    } else {
      setRating(0);
    }
    // if (newValue > 0) {
    //   setIsRated(true);
    // }
  };

  const handleSubmit = () => {
    onSubmit(rating);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        {isRated ? "Thank you for your rating" : "Your opinion matters to us!"}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Rating
          name="half-rating"
          value={rating}
          precision={0.5}
          onChange={handleRatingChange}
          size="large"
          sx={{
            fontSize: 60, // Increased star size
            "& .MuiRating-iconFilled": {
              marginTop: "5px", // Apply margin to filled stars (whole and half)
            },
            "& .MuiRating-iconHalf": {
              marginTop: "5px", // Apply margin to half stars only
            },
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={rating === 0}
          variant="contained"
          style={{
            position: "relative",
            borderRadius: "50px",
            padding: "10px 20px",
            minWidth: "9rem",
            margin: "1rem 0",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
