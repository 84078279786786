import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import OtpInput from "./OtpInput";
import AnimatedDialog from "./AnimatedDialog";
import SuccessPopup from "./SuccessPopup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { notifyError } from "../../utils/notificationService";
import CloseIcon from "@mui/icons-material/Close";
import { verifyUpdateRequestOtp } from "../../features/Profile/userSlice";
const OtpModal = ({
  open,
  handleClose,
  timer,
  setTimer,
  handleResendOTP,
  updateUser,
  handleVerifyOTPSuccess,
  fromEditForm1,
}) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpInput, setOtpInput] = useState(Array(6).fill(""));
  useEffect(() => {
    if (!open) {
      setOtp("");
      setOtpInput(Array(6).fill(""));
    }
  }, [open]);

  const navigate = useNavigate();
  const { user_id, userOtp, userStatusCode } = useUserAuth();
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  useEffect(() => {
    // console.log(timer,"timer")
    if (timer > 0) {
      const countDown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countDown);
    }
  }, [timer]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleVerifyOTP = async () => {
    setSubmitting(true);
    try {
      let data = {
        user_id: user_id ?? "",
        otp: otp,
      };
      let { payload } = await dispatch(verifyUpdateRequestOtp(data));
      if (payload.status === 200) {
        setSuccessPopupOpen(true);
        handleClose();
        handleVerifyOTPSuccess();
      } else {
        notifyError(payload.msg);
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVerify = () => {
    if (updateUser) {
      handleVerifyOTP();
    } else if (otp === userOtp) {
      setSuccessPopupOpen(true);
      handleClose();
    } else {
      notifyError(`Invalid OTP`);
    }
  };
  // console.log(user_id, userStatusCode === 200, userStatusCode);
  const handleSuccessPopupClose = () => {
    if (!updateUser) {
      if (user_id && userStatusCode === 200) {
        navigate("/dashboard");
      } else {
        navigate("/signup");
      }
    }

    setSuccessPopupOpen(false);
  };
  const handleResendClick = (e) => {
    e.preventDefault();
    handleResendOTP();
    setOtp("");
    setOtpInput(Array(6).fill(""));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && otp?.length === 6) {
      handleVerify();
    }
  };
  return (
    <>
      <AnimatedDialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Verification Code</Typography>
          <IconButton
            edge="end"
            color="error"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body2" color={"secondary"}>
            Enter the OTP sent to your{" "}
            {fromEditForm1 || "mobile number or email"}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            my={2}
            onKeyDown={handleKeyDown}
          >
            <OtpInput
              length={6}
              onChange={handleOtpChange}
              shouldFocusFirst={open}
              otp={otpInput}
              setOtp={setOtpInput}
            />
          </Box>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleVerify}
            color="primary"
            disabled={otp?.length < 6 || isSubmitting}
          >
            {isSubmitting ? " Verify..." : "Verify"}
          </Button>
          {timer > 0 ? (
            <Typography variant="body2" align="center">
              Resend OTP in {timer} seconds
            </Typography>
          ) : (
            <Typography variant="body2" align="center">
              Didn’t receive the OTP?{" "}
              <Link href="#" onClick={handleResendClick}>
                Resend Code
              </Link>
            </Typography>
          )}
        </DialogContent>
        {/* <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleVerify} color="primary">
          Verify
        </Button>
      </DialogActions> */}
      </AnimatedDialog>

      <SuccessPopup
        open={successPopupOpen}
        handleClose={handleSuccessPopupClose}
      />
    </>
  );
};

export default OtpModal;
