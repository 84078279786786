import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TimeIcon from "../../assets/images/calendar-clock 1.png";
import Marker from "../../assets/images/marker9.png";
import { getUser } from "../../utils/authUtils";
import PaymentMethod from "../Payment/Payment";
import insurance from "../../assets/images/insurance1.png";
import { IoChevronForward } from "react-icons/io5";
import Tag from "../../assets/images/Tag.png";
import { Modal, ModalBody } from "reactstrap";
import { MdOutlineCancel } from "react-icons/md";
import { MakePaymentAPI } from "../../api/VenderDetails/VenderDetailsAPI";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import Loader from "../../components/comman/Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { RiMapPinFill } from "react-icons/ri";
const ChatAssign = () => {
  const [paymentType, setPaymentType] = useState("pesapal");
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorDetail, JobDetail } = location.state || {};
  const { mobile, user_id } = getUser();
  const [mobileNumber, setMobileNumber] = useState(mobile);
  const [isPayment, setIsPayment] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [Couponmodal, setCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [provider_id, setProvider_id] = useState("");
  const [Isdiscount, setIsDiscount] = useState("");
  const [tsxPercentage, setTaxPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentExist, setPaymentExist] = useState(false);
  const [isOptionalAddress, setIsOptionalAddress] = useState("");
  const [address, setAddress] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState({
    catImage: "",
    catName: "",
    subCatImage: "",
    subCatName: "",
    venderName: "",
    serviceDetails: "",
    jobDate: "",
    jobtime: "",
    serviceBudget: "",
    JobAddress: "",
    baradCastId: "",
  });
  const [totalCharges, setTotalCharges] = useState(0);
  const [fees, setFees] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timeString) => {
    const [hour, minute, second] = timeString.split(":");
    let hours = parseInt(hour, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12
    return `${hours}:${minute} ${ampm}`;
  };

  const handleClearFromPayment = () => {
    navigate("/broadcast-list");
  };

  useEffect(() => {
    if (JobDetail && JobDetail.length > 0) {
      const totalCharges = JobDetail[0].job_budget || 0;
      const fees = JobDetail[0]?.category.category_fees || 0;
      const taxRate = JobDetail[0]?.category.category_tax || 0;
      setTaxPercentage(taxRate);
      // const calculatedTax = calculateTax(totalCharges, fees, taxRate);

      setCategoryDetails({
        catImage: JobDetail[0]?.category?.category_image || "",
        catName: JobDetail[0]?.category?.category_name || "",
        subCatImage: JobDetail[0]?.sub_category?.sub_category_image || "",
        subCatName: JobDetail[0]?.sub_category?.sub_category_name || "",
        venderName: vendorDetail?.name || "",
        serviceDetails: JobDetail[0].job_detail || "",
        jobDate: JobDetail[0].job_dt ? formatDate(JobDetail[0].job_dt) : "",
        jobtime: JobDetail[0].job_tm ? formatTime(JobDetail[0].job_tm) : "",
        serviceBudget: totalCharges,
        JobAddress: JobDetail[0].job_location || "",
        baradCastId: JobDetail[0].broadcast_id || "",
        lat: JobDetail[0].job_lat || 0,
        lng: JobDetail[0].job_long || 0,
      });

      setProvider_id(vendorDetail?.vendor_id);
      setTotalCharges(totalCharges);
      setFees(fees);
      // setTax(calculatedTax);
      const address = JobDetail[0].job_location || "";
      const cleanAddress = address?.startsWith("$")
        ? address?.slice(1)
        : address;
      let optionalAddress = "";
      let firstAddress = "";
      if (cleanAddress) {
        [optionalAddress, firstAddress] = cleanAddress?.split("#");
        firstAddress = address?.startsWith("$") ? firstAddress : address;
        optionalAddress = address?.startsWith("$") ? optionalAddress : "";
      }
      setAddress(firstAddress);
      setIsOptionalAddress(optionalAddress);
    }
  }, [JobDetail]);

  const handleInputChange = async (e) => {
    setCoupon(e.target.value);
  };

  const handleApplyCoupon = async () => {
    setIsLoading(true);
    let data = {
      user_id: user_id,
      provider_id: provider_id,
      coupon_code: coupon,
    };
    try {
      const response = await MakePaymentAPI.applyCoupon(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        notifySuccess("Coupon Code Applieced Successfully");
        setCouponModal(false);
        setCouponCode(coupon);
        setIsDiscount(responseData);
      } else {
        setCouponModal(false);
        notifyError(responseData.msg);
        setCoupon("");
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const HandelRemove = () => {
    setCoupon("");
    setCouponCode("");
    setIsDiscount("");
  };

  const calculateTax = () => {
    const totalAmount =
      Number(totalCharges) + Number(fees) - calculateDiscount();

    const tax = (totalAmount * tsxPercentage) / 100;

    return tax.toFixed(2); // Round to 2 decimal places
  };

  const calculateDiscount = () => {
    if (Isdiscount) {
      const totalDiscount =
        (Number(Isdiscount.coupon_discount) * Number(totalCharges)) / 100;
      const applicableDiscount =
        totalDiscount > Isdiscount.max_discount_ksh
          ? Isdiscount.max_discount_ksh
          : totalDiscount;
      return applicableDiscount.toFixed(2);
    } else {
      return 0;
    }
  };

  const [isMaxDiscount, setIsMaxDiscount] = useState(false);
  useEffect(() => {
    if (Isdiscount) {
      const totalDiscount =
        (Number(Isdiscount.coupon_discount) * Number(totalCharges)) / 100;
      if (totalDiscount > Isdiscount.max_discount_ksh) {
        setIsMaxDiscount(true);
      } else {
        setIsMaxDiscount(false);
      }
    } else {
      setIsMaxDiscount(false);
    }
  }, [Isdiscount, totalCharges]);

  const calculateTotal = () => {
    let finalAmount = 0;

    if (Isdiscount) {
      const totalDiscount =
        (Number(Isdiscount.coupon_discount) * Number(totalCharges)) / 100;
      const applicableDiscount =
        totalDiscount > Isdiscount.max_discount_ksh
          ? Isdiscount.max_discount_ksh
          : totalDiscount;
      finalAmount =
        Number(totalCharges) -
        applicableDiscount +
        Number(fees) +
        Number(calculateTax());

      // Update the isMaxDiscount state to false
      setIsMaxDiscount(false);
    } else {
      finalAmount =
        Number(totalCharges) + Number(fees) + Number(calculateTax());
    }

    return finalAmount;
  };

  const totalAmount = useMemo(
    () => calculateTotal(),
    [Isdiscount, totalCharges, fees, tax]
  );
  // "2024-02-03T8:04"
  let formattedDateTime = "";
  if (JobDetail[0]) {
    const jobDate = JobDetail[0].job_dt; // "2024-07-25"
    const jobTime = JobDetail[0].job_tm; // "07:22:00"

    // Manually construct the date-time string
    formattedDateTime = `${jobDate}T${jobTime.slice(0, 5)}`;
  }

  const PostJob = async () => {
    let data = {
      user_id: user_id,
      brodcast_id: categoryDetails.baradCastId,
      provider_id: provider_id,
      service_list: null,
      service_charge: totalCharges,
      coupon_discount: Isdiscount ? Isdiscount.coupon_discount : 0,
      discount_amt: calculateDiscount(),
      tax: tsxPercentage,
      tax_amt: calculateTax(),
      fees: fees,
      total_amt: totalAmount,
      payment_method: paymentType,
      date_time: formattedDateTime,
      job_location: categoryDetails.JobAddress,
      job_lat: categoryDetails.lat,
      job_long: categoryDetails.lng,
      coupon_code: couponCode,
    };

    try {
      setIsLoading(true);
      const response = await MakePaymentAPI.postJob(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        // notifySuccess(responseData.msg);
        window.location.href = responseData?.payment_data?.redirect_url;
      } else {
        notifyError(responseData.msg);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const handelBack = () => setPaymentExist(true);

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="col-lg-12">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "#0079EA", height: "65px" }}
        >
          <p
            className="ms-md-5"
            style={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            Broadcast Job Summary
          </p>
          <IoArrowBackCircleOutline
            onClick={handelBack}
            style={{ color: "#FFFFFF" }}
            size={35}
            className="me-md-5"
            cursor="pointer"
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h4
              style={{
                color: "#1E1E1E",
                fontWeight: "700",
                fontFamily: "Nunito",
                fontSize: "18px",
                lineHeight: "24.5px",
              }}
            >
              Date & Time
            </h4>
            <div className="CostomCard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: "#E8F3FD",
                      borderRadius: "5px",
                      width: "34px",
                      height: "34px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <img src={TimeIcon} alt="Time Icon" />
                  </div>
                  <p style={{ margin: 0 }}>
                    {categoryDetails.jobDate}
                    {","} {categoryDetails.jobtime}
                  </p>
                </div>
              </div>
            </div>
            <h4
              style={{
                color: "#1E1E1E",
                fontWeight: "700",
                fontFamily: "Nunito",
                fontSize: "18px",
                lineHeight: "24.5px",
              }}
            >
              Address
            </h4>
            <div className="CostomCard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: "#E8F3FD",
                      borderRadius: "5px",
                      width: "34px",
                      height: "34px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <img src={Marker} alt="Marker Icon" />
                  </div>
                  <p style={{ margin: 0 }}>{address}</p>
                </div>
              </div>
            </div>

           

            {isOptionalAddress && (
              <>
                 <h4
              style={{
                color: "#1E1E1E",
                fontWeight: "700",
                fontFamily: "Nunito",
                fontSize: "18px",
                lineHeight: "24.5px",
              }}
            >
              Building no or name or street name
            </h4>
            <div className="CostomCard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: "#E8F3FD",
                      borderRadius: "5px",
                      width: "34px",
                      height: "34px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <img src={Marker} alt="Marker Icon" />
                  </div>
                  <p style={{ margin: 0 }}>{isOptionalAddress}</p>
                </div>
              </div>
            </div>
              </>
            )}

            <h4
              style={{
                color: "#1E1E1E",
                fontWeight: "700",
                fontFamily: "Nunito",
                fontSize: "18px",
                lineHeight: "24.5px",
              }}
            >
              Payment Method
            </h4>
            <PaymentMethod
              setPaymentType={setPaymentType}
              paymentType={paymentType}
            />

            <div className="d-flex">
              <img
                src={insurance}
                alt="Insurance"
                height={30}
                width={30}
                margin={"auto"}
              />
              <p className="mx-1">
                By confirming checkout, I agree to the{" "}
                <span
                  style={{
                    fontWeight: "400",
                    color: "#0079EA",
                    cursor: "pointer",
                  }}
                >
                  service policy
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontWeight: "400",
                    color: "#0079EA",
                    cursor: "pointer",
                  }}
                >
                  refund policy
                </span>
                . I also agree to pay full amount of booking.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <div>
              <h4
                style={{
                  color: "#1E1E1E",
                  fontWeight: "700",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  lineHeight: "24.5px",
                }}
              >
                Coupon
              </h4>
              <div className="CostomCard1">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        borderRadius: "5px",
                        width: "34px",
                        height: "34px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <img src={Tag} alt="Marker Icon" />
                    </div>
                    <p
                      style={{
                        color: "#444444",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >
                      {couponCode ? couponCode : "Coupons for off"}
                    </p>
                  </div>
                  {couponCode ? (
                    <button
                      style={{
                        color: "#FF0000",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                      }}
                      onClick={HandelRemove}
                    >
                      remove
                    </button>
                  ) : (
                    <IoChevronForward
                      size={20}
                      onClick={() => setCouponModal(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <h4
              style={{
                color: "#1E1E1E",
                fontWeight: "700",
                fontFamily: "Nunito",
                fontSize: "18px",
                lineHeight: "24.5px",
              }}
            >
              Payment Summary
            </h4>{" "}
            <div className="CostomCard">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p
                  style={{
                    color: "#444444",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  Total Charges
                </p>
                <p
                  style={{
                    color: "#444444",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  KES {totalCharges}
                </p>
              </div>

              {Isdiscount && (
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p
                    style={{
                      color: "green",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "21px",
                      margin: 0,
                    }}
                  >
                    Offered Discount {/* {!isMaxDiscount && ( */}
                    <span
                      style={{
                        color: "green",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        margin: 0,
                      }}
                    >{`(${Isdiscount.coupon_discount}%)`}</span>
                    {/* )} */}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "21px",
                      margin: 0,
                    }}
                  >
                    - KES {calculateDiscount()}
                  </p>
                </div>
              )}

              {/*  */}
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p
                  style={{
                    color: "#444444",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  Fees
                </p>
                <p
                  style={{
                    color: "#444444",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  + KES {fees}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <p
                  style={{
                    color: "#FF0000",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  Tax{" "}
                  <span
                    style={{
                      color: "#FF0000",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "21px",
                      margin: 0,
                    }}
                  >{`(${tsxPercentage}%)`}</span>
                </p>
                <p
                  style={{
                    color: "#FF0000",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: 0,
                  }}
                >
                  + KES {calculateTax()}
                </p>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    lineHeight: "27px",
                    margin: 0,
                  }}
                >
                  Total Amount
                </p>
                <p
                  style={{
                    color: "#1E1E1E",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    lineHeight: "27px",
                    margin: 0,
                  }}
                >
                  KES {totalAmount.toFixed(2)}
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary mb-2"
              style={{ width: "100%" }}
              onClick={PostJob}
              //   disabled={!isPayment}
            >
              Pay
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={Couponmodal} centered>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h6
              style={{
                fontSize: "22px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
                color: "#2F2F2F",
                margin: 0,
              }}
            >
              Add Coupon
            </h6>
            <MdOutlineCancel
              onClick={() => {
                setCouponModal(false);
              }}
              style={{
                cursor: "pointer",
                width: "30px",
                height: "30px",
                color: "#2F2F2F",
              }}
            />
          </div>
          <hr></hr>
          <div className="custom-modal-body">
            <div className="custom-input-group">
              <input
                type="text"
                className="custom-form-control"
                placeholder="Enter Coupon Code"
                value={coupon}
                onChange={handleInputChange}
                aria-label="Enter Coupon Code"
              />
              <button
                className={`custom-btn custom-btn-primary ${
                  coupon ? "enabled" : "disabled"
                }`}
                type="button"
                disabled={!coupon}
                onClick={handleApplyCoupon}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={paymentExist} centered style={{ zIndex: 9999 }}>
        <ModalBody>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            Leave ?
          </div>
          <p style={{ textAlign: "center", marginTop: "2rem" }}>
            Are you sure you want to leave the payment page?
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-primary mx-1"
              onClick={handleClearFromPayment}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-danger mx-1"
              onClick={() => setPaymentExist(!paymentExist)}
            >
              No
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChatAssign;
