import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../utils/authUtils";
import { viewProfile } from "../features/Profile/profileSlice";
import { fetchNotifications } from "../features/Notification/notificationsSlice";

const AuthContext = createContext();

const UserAuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user, userData, loading, userStatusCode } = useSelector(
    (state) => state.auth
  );
  const { user_id, otp: userOtp } = user ?? {};
  const API_KEY = "AIzaSyCWMiHj0u2G1P_FTrKGqf5zPTD1VrVGL8o";
  const libraries = ["places"];

  // please comment this dispatch line for stop notification
  function getNotification() {
    dispatch(fetchNotifications({ user_id: user_id }));
  }
  const contextValue = {
    user_id,
    userOtp,
    userData,
    API_KEY,
    libraries,
    loading,
    getNotification,
    userStatusCode,
  };
  useEffect(() => {
    if (user_id) {
      dispatch(viewProfile({ user_id: user_id }));
    }
  }, [dispatch, user_id]);

  function getNotification() {
    if (user_id) {
      dispatch(fetchNotifications({ user_id: user_id }));
    }
  }

  useEffect(() => {
    getNotification();
    const intervalId = setInterval(getNotification, 10000);
    return () => clearInterval(intervalId);
  }, [dispatch, user_id]);
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default UserAuthProvider;

export const useUserAuth = () => {
  return useContext(AuthContext);
};
