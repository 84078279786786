import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Divider,
  Grid,
  Container,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  StyledBox,
  StyledIconButton,
  StyledOrderBox,
} from "../../components/UI/StyleBox";
import { BackButton, CustomBlockButton } from "../../components/UI/Button";
import ChatIcon from "@mui/icons-material/Chat";
import {
  StyleDateTimeBox,
  StyleTypography,
} from "../../components/UI/StyleBox";
import { getUser } from "../../utils/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails } from "../../features/Order/ordersSlice";
import withRouter from "../../components/comman/withRouter";
import { formatDateTime } from "../../utils/dateUtils";
import Loader from "../../components/comman/Loader";
import CancelJobDialog from "./CancelJobDialog";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ApproveServiceApis } from "../../api/ApproveService/Approve";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import RatingDialog from "../../components/comman/RatingDialog";
import StatusTypography from "../../components/comman/StatusTypography";
const ApproveService = ({ router }) => {
  const { params } = router;
  const navigate = useNavigate();
  const location = useLocation();
  const mode = location?.state?.mode;
  const handleBack = () => navigate(-1);

  const { user_id } = getUser();
  const dispatch = useDispatch();
  const { userOrders, loading, orderDetails } = useSelector(
    (state) => state.orders
  );

  const [ApproveJob, setApprovedJob] = useState(false);

  const {
    address,
    vendor_name,
    job_no,
    job_status,
    date_time,
    total_service,
    total_amount,
    tax,
    service_list,
    service_charge,
    ratings,
    fees,
    vendor_id,
    tax_amt,
    is_cust_mark_completed,
    is_completed,
    cannceled_by,
    canncel_reason,
  } = orderDetails ?? {};
  const cleanAddress = address?.startsWith("$") ? address?.slice(1) : address;
  let optionalAddress = "";
  let firstAddress = "";
  if (cleanAddress) {
    [optionalAddress, firstAddress] = cleanAddress?.split("#");
    firstAddress = address?.startsWith("$") ? firstAddress : address;
    optionalAddress = address?.startsWith("$") ? optionalAddress : "";
  }
  const { formattedDate, formattedTime } = formatDateTime(date_time);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ratingDialog, setRatingDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => setRatingDialog(true);
  const handleClose = () => setRatingDialog(false);

  //   {
  //     "user_id": 90,
  //     "rating_user_id": 79,
  //     "job_id" : 302,
  //     "rating" : 2,
  //     "review" : "test rating"
  // }

  const handleRatingSubmit = async (rating) => {
    setIsLoading(true);
    let data = {
      user_id: user_id,
      rating_user_id: vendor_id,
      job_id: params.order_id,
      rating: rating,
      review: "test rating",
    };
    try {
      const response = await ApproveServiceApis.giveRatings(data);
      console.log(response);
      let req = response.data;
      if (req.status === 200) {
        notifySuccess(req.msg);
        setRatingDialog(false);
        dispatch(
          fetchOrderDetails({
            user_id: user_id,
            order_id: params.order_id,
            job_type: params.job_type,
          })
        );
      } else {
        notifyError(req.msg);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  useEffect(() => {
    dispatch(
      fetchOrderDetails({
        user_id: user_id,
        order_id: params.order_id,
        job_type: params.job_type,
      })
    );
  }, [dispatch, params.order_id, user_id]);

  const handleApprove = async () => {
    setApprovedJob(true);
    let data = {
      post_id: params.order_id,
    };
    try {
      const respone = await ApproveServiceApis.customerMarkAsCompleted(data);
      let req = respone.data;
      if (req.status === 200) {
        notifySuccess(req.msg);
        handleOpen();
        // dispatch(
        //   fetchOrderDetails({ user_id: user_id, order_id: params.order_id })
        // );
      } else {
        notifyError(req.msg);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setApprovedJob(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            Job Details
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </StyledBox>
      <StyledOrderBox>
        <Container>
          <Card>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Box sx={{ p: 2 }}>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    sx={{ backgroundColor: "#E8F3FD", padding: 2, mb: 4 }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Typography variant="h6">{vendor_name}</Typography>
                      <Box display={"flex"} alignItems={"center"}>
                        <StarIcon
                          style={{ color: "#FFD700", fontSize: "1.5rem" }}
                        />
                        {/* Yellow star icon */}
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "left", fontSize: "1rem" }}
                        >
                          {ratings?.toFixed(1)}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Job No: {job_no}
                      </Typography>{" "}
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                      <StatusTypography status={job_status} />
                      {/* <Chip
                        label={job_status}
                        sx={{ backgroundColor: "#FF7A00", color: "#fff" }}
                      /> */}
                      {/* <NavLink to={`/chat/${vendor_id}` } state={{ mode: "", jobDetail: "" }}>
                        <StyledIconButton>
                          <ChatIcon fontSize="small" />
                        </StyledIconButton>
                      </NavLink> */}
                    </Box>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    <StyleTypography>Date & Time</StyleTypography>
                    <StyleDateTimeBox>
                      <EventIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        {formattedDate}, {formattedTime}
                      </Typography>
                    </StyleDateTimeBox>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    <StyleTypography> Address</StyleTypography>
                    <StyleDateTimeBox mt={1}>
                      <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        textAlign={"left"}
                      >
                        {firstAddress}
                      </Typography>
                    </StyleDateTimeBox>
                    {optionalAddress && (
                      <>
                        <h4
                          style={{
                            color: "#1E1E1E",
                            fontWeight: "700",
                            fontFamily: "Nunito",
                            fontSize: "18px",
                            lineHeight: "24.5px",
                            marginTop: ".5rem",
                          }}
                        >
                          Building no or name or street name (Optional)
                        </h4>
                        <StyleDateTimeBox mt={1}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign={"left"}
                          >
                            {optionalAddress}
                          </Typography>
                        </StyleDateTimeBox>
                      </>
                    )}
                  </Box>
                </Box>
                {/* <Box m={2} display={"flex"}>
                  <ErrorOutlineIcon color="primary" sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign={"left"}
                  >
                    As a customer, you can manage your offerings. Feel free to{" "}
                    <Button
                      onClick={handleOpenDialog}
                      sx={{
                        color: "#0099ff",
                        textTransform: "capitalize",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      cancel
                    </Button>{" "}
                    this job if needed. Tap here to initiate the{" "}
                    <Button
                      onClick={handleOpenDialog}
                      sx={{
                        color: "#0099ff",
                        textTransform: "capitalize",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      cancellation process
                    </Button>
                    .
                  </Typography>
                </Box> */}
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <StyleTypography sx={{ mb: 1 }}>
                    Payment Summary
                  </StyleTypography>

                  <Box
                    sx={{
                      padding: "1.5rem",
                      border: "1px solid rgba(60, 64, 67, 0.15)",
                      borderRadius: ".5rem",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 2 }}
                      >
                        No. of Services
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                        {total_service}
                      </Typography>{" "}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body2" color="text.secondary">
                        Total Charges
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        KES {service_charge}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                      <Typography variant="body2" color="text.secondary">
                        Fee
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        KES {fees}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                      <Typography variant="body2" color="red">
                        Taxes {tax}%
                      </Typography>
                      <Typography variant="body2" color="red">
                        KES {Number(tax_amt).toFixed(2)}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Total Amount
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        KES {total_amount}
                      </Typography>
                    </Box>
                  </Box>

                  <StyleTypography variant="body1" sx={{ fontWeight: "bold" }}>
                    Added Services (1)
                  </StyleTypography>
                  <Box>
                    {service_list?.map((service, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        p={1}
                        border="1px solid #0079EA"
                        borderRadius={1}
                      >
                        <Box display="flex" alignItems="center">
                          <img src={service.service_image} width={38} />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mx: 1 }}
                          >
                            {service.service_name}
                          </Typography>{" "}
                        </Box>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          KES {service.service_amount}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ textAlign: "left" }}>
                    {cannceled_by === "" ? (
                      is_completed &&
                      (is_cust_mark_completed ? (
                        <Typography
                          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                          You have{" "}
                          <span style={{ color: "#0079EA" }}>approved</span>{" "}
                          this job already{" "}
                        </Typography>
                      ) : (
                        <Button
                          onClick={handleApprove}
                          style={{
                            width: "100%",
                            backgroundColor: "#4d134d",
                            color: "white",
                            marginTop: "1rem",
                            textTransform: "none",
                          }}
                          disabled={ApproveJob}
                        >
                          {ApproveJob ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Approve"
                          )}
                        </Button>
                      ))
                    ) : (
                      <>
                        <Typography
                          color="text.secondary"
                          sx={{ marginTop: "1rem", textAlign: "left" }}
                        >
                          Job is cancelled by {cannceled_by}
                        </Typography>
                        {canncel_reason && (
                          <Typography
                            sx={{ marginBottom: "1rem", textAlign: "left" }}
                          >
                            Cancel Reason :
                            <span style={{ fontSize: ".8rem" }}>
                              {" "}
                              {canncel_reason}
                            </span>{" "}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </StyledOrderBox>
      <CancelJobDialog open={dialogOpen} onClose={handleCloseDialog} />

      <div>
        {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Rate Service
      </Button> */}
        <RatingDialog
          open={ratingDialog}
          onClose={handleClose}
          isLoading={isLoading}
          onSubmit={handleRatingSubmit}
          serviceName="Service Place"
        />
      </div>
    </>
  );
};

export default withRouter(ApproveService);
