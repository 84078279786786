// const BASE_URL = "http://191.101.14.101:8000";
// const BASE_URL = "http://api.suluhu.net:8040"
const BASE_URL = "https://api.suluhu.net";
// const BASE_URL = "http://191.101.14.101:8040";
// const BASE_URL = "http://191.101.14.101";

// const BASE_URL = "http://api.suluhu.net:8040";

const endpoints = {
  login: "/Login/",
  signup: "/Signup/",
  restore_account: "/restore_account/",
  customer_home_page: "/customer_home_page/",
  nearby_vendors: "/nearby_vendors/",
  update_profile: "/update_profile/",
  view_profile: "/view_profile/",
  delete_account: "/delete_account/",
  update_mobile_email: "/update_mobile_email/",
  verify_update_request_otp: "/verify_update_request_otp/",
  my_order: "/my_order/",
  order_details: "/order_details/",
  cancel_job: "/cancel_job/",
  customer_mark_as_completed: "/customer_mark_as_completed/",
  favourite_vendor_list: "/favourite_vendor_list/",
  favourite_vendor: "/favaourite_vendor/",
  vendor_details: "/vendor_details/",
  recived_notifications: "/recived_notifications/",
  broadcast_list: "/broadcast_list/",
  broadcast_response: "/broadcast_response/",
  order_history: "/order_history/",
};

export { BASE_URL, endpoints };
