import React from "react";
import { Typography } from "@mui/material";

const statusStyles = {
  "PENDING APPROVAL": {
    color: "#FF7A00",
    border: "1px solid #FF7A00",
  },
  CONFIRMED: {
    color: "#37AFE1",
    border: "1px solid #37AFE1",
  },
  PENDING: {
    color: "#E2A700",
    border: "1px solid #E2A700",
  },
  REJECTED: {
    color: "#D32F2F",
    border: "1px solid #D32F2F",
  },
  COMPLETED: {
    color: "#388E3C",
    border: "1px solid #388E3C",
  },
  CANCELLED: {
    color: "#FF0000",
    border: "1px solid #FF0000",
  },
  BOOKED: {
    color: "#C62300", // Default color if status is not found
    border: "1px solid #C62300", // Default border
  },
  // Add more cases if necessary
};

const StatusTypography = ({ status }) => {
  const currentStatusStyle = statusStyles[status] || {
    color: "#000000", // Default color if status is not found
    border: "1px solid #000000", // Default border
  };

  return (
    <>
      <Typography
        variant="body2"
        sx={{
          ...currentStatusStyle,
          borderRadius: "4px",
          padding: "2px 8px",
          fontWeight: "bold",
          fontSize: "0.875rem",
        }}
      >
        {status}
      </Typography>
    </>
  );
};

export default StatusTypography;
