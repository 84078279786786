import React, { memo, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  styled,
  Avatar,
  Divider,
  Chip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersList } from "../../features/Order/ordersSlice";
import { getUser } from "../../utils/authUtils";
import Loader from "../../components/comman/Loader";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { StyledBox, StyledOrderBox } from "../../components/UI/StyleBox";
import { BackButton } from "../../components/UI/Button";
import {
  formatDateTime,
  formatDateTimeForBroadcast,
} from "../../utils/dateUtils";
import MotionCard from "../../components/MotionCard/MotionCard";
import "./Search.css";
import { IoIosSearch } from "react-icons/io";
import StatusTypography from "../../components/comman/StatusTypography";

export const OrderCard = memo(
  ({
    order,
    broadcast,
    date,
    time,
    Id,
    name,
    jobNo,
    list,
    status,
    totalAmount,
    discription,
    response,
    isBroadCast = false,
    // is_started,
    // is_completed,
  }) => {
    const {
      is_started,
      is_completed,
      order_id,
      assigned_provider_id,
      assigned_provider,
    } = order || {};
    const dates = isBroadCast
      ? formatDateTimeForBroadcast(date, time)
      : formatDateTime(date, time);

    const navigate = useNavigate();

    const statusStyles = {
      CONFIRMED: {
        color: "#37AFE1",
        border: "1px solid #37AFE1",
      },
      PENDING: {
        color: "#E2A700",
        border: "1px solid #E2A700",
      },
      REJECTED: {
        color: "#D32F2F",
        border: "1px solid #D32F2F",
      },
      COMPLETED: {
        color: "#388E3C",
        border: "1px solid #388E3C",
      },
      CANCELLED: {
        color: "#9E9E9E",
        border: "1px solid #9E9E9E",
      },
      // Add more cases as needed
    };

    const currentStatusStyle = statusStyles[status] || {
      color: "#000000", // default color if status is not found
      border: "1px solid #000000", // default border
    };

    const handelClick = () => {
      if (status === "COMPLETED" && broadcast) {
        navigate(`/approve-service/${order_id}`);
      } else {
        navigate(
          `/${broadcast ? "broadcast-detail" : "job-details"}/${Id}/${
            order?.job_type
          }`
        );
      }
    };

    return (
      <MotionCard>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            borderRadius: "8px",
            padding: "0.5rem",
            cursor: "pointer",
            width: "100%",
            maxWidth: 520,
            margin: "16px auto",
          }}
          onClick={() => handelClick()}
        >
          <CardContent>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} alignItems={"center"}>
                {broadcast ? (
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    sx={{ fontWeight: "bold", fontSize: "1rem" }}
                  >
                    Job No: {jobNo}
                  </Typography>
                ) : (
                  <>
                    <Avatar sx={{ mr: 1, width: 24, height: 24 }} />
                    <Typography
                      variant="h6"
                      component="div"
                      color="primary"
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {name}
                    </Typography>{" "}
                  </>
                )}
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <EventIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {dates.formattedDate}
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {dates.formattedTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {broadcast ? (
              <>
                <Box display="flex" alignItems="center" mt={1}>
                  <>
                    <img
                      src={list?.category?.category_image}
                      alt={list?.category?.category_name}
                      style={{ width: 28 }}
                    />
                    <Typography
                      variant="h6"
                      color="black"
                      sx={{ fontSize: "1.2rem", marginLeft: 1 }}
                    >
                      {list?.category?.category_name}
                    </Typography>
                  </>
                </Box>
                <Box display="flex" alignItems="center" ml={4}>
                  <>
                    <img
                      src={list?.sub_category?.sub_category_image}
                      alt={list?.sub_category?.sub_category_name}
                      style={{ width: 22 }}
                    />
                    <Typography
                      variant="h6"
                      color="secondary"
                      sx={{ fontSize: "0.9rem", margin: 1 }}
                    >
                      {list?.sub_category?.sub_category_name}
                    </Typography>
                  </>
                </Box>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "start", fontSize: "0.875rem" }}
                >
                  Order No: {jobNo}
                </Typography>
                <Box display="flex" alignItems="center" mt={1} mb={1}>
                  {list?.map((service) => (
                    <>
                      <Avatar
                        src={service.service_image}
                        alt={service?.service_name}
                        sx={{ mr: 1, width: 24, height: 24 }}
                      />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "0.875rem" }}
                      >
                        {service?.service_name}
                      </Typography>
                    </>
                  ))}
                </Box>
              </>
            )}
            <p
              style={{ fontWeight: "200", color: "#817e7e", fontSize: "12px" }}
            >
              {discription}
            </p>
            <Divider
              sx={{
                borderColor: "orange",
                borderStyle: "dashed",
                my: 1,
              }}
            />
            <Box display="flex" justifyContent={"space-between"}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "0.875rem" }}
              >
                Service Budget: KES {totalAmount}
              </Typography>
              <StatusTypography status={status} />
            </Box>
            <Box display="flex" justifyContent={"space-between"} sx={{ mt: 2 }}>
              {!(
                status === "CONFIRMED" ||
                status === "CANCELLED" ||
                status === "CANCELED" ||
                status === "COMPLETED"
              ) &&
                response > 0 && (
                  <Box>
                    <Chip label={`${response} Interested`} color="primary" />
                  </Box>
                )}
              {assigned_provider_id && assigned_provider && (
                <Box>
                  <Chip
                    label={`Assigned : ${assigned_provider} `}
                    color="primary"
                    sx={{
                      padding: "2px",
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                    }}
                  />
                </Box>
              )}
              {is_started === true && is_completed === false && (
                <Box>
                  <Chip
                    label="Started"
                    style={{
                      padding: "2px",
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      backgroundColor: "#5493cd",
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  />
                </Box>
              )}{" "}
            </Box>
          </CardContent>
        </Card>{" "}
      </MotionCard>
    );
  }
);

const MyOrderList = () => {
  const navigate = useNavigate();
  const { user_id } = getUser();
  const dispatch = useDispatch();
  const { userOrders, loading } = useSelector((state) => state.orders);
  const [isSearch, setIsSearch] = useState(false);
  const handleSearchClick = () => {
    setIsSearch(true);
  };
  useEffect(() => {
    dispatch(fetchOrdersList({ user_id: user_id }));
  }, [dispatch]);

  useEffect(() => {
    // Only apply the back button prevention on the protected page
    if (location.pathname === "/my-order") {
      window.history.pushState(null, "", window.location.href);

      const handlePopState = (event) => {
        window.history.pushState(null, "", window.location.href);
        navigate(window.location.pathname);
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, []);

  const handleBack = () => {
    navigate("/dashboard");
  };
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOrders = (userOrders?.order_list || []).filter((order) => {
    const vendorName = order.vendor_name ? order.vendor_name.toLowerCase() : "";
    const jobNo = order.job_no ? order.job_no : "";
    const query = searchQuery.toLowerCase();

    return vendorName.includes(query) || jobNo.includes(query);
  });
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setIsFocused(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleSearchChange(e); // Call handleSearchChange when input changes
  };

  const toggleFocus = () => {
    if (isFocused && inputValue === "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            My Order
          </Typography>
        </Box>
        <Box>
          <form className={`search ${isFocused ? "focused" : ""}`}>
            <input
              type="number"
              className={`search__input ${isFocused ? "focused" : ""}`}
              placeholder="Search..."
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="search__button "
              onClick={toggleFocus}
            >
              <IoIosSearch size={23} color="#FFFFFF" />
            </button>
          </form>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
        {/* {isSearch && (
          <Box>
            <input type="text" placeholder="Search..." />
          </Box>
        )} */}
      </StyledBox>
      <StyledOrderBox>
        <Grid container spacing={3}>
          {Array.isArray(filteredOrders) && filteredOrders.length > 0 ? (
            filteredOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <OrderCard
                  date={order.date_time}
                  Id={order.order_id}
                  name={order.name}
                  jobNo={order.job_no}
                  list={order?.service_list}
                  totalAmount={order.total_amount}
                  status={order.job_status}
                  // is_started={order.is_started}
                  // is_completed={order.is_completed}
                  order={order}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  padding: 2,
                  color: "gray",
                }}
              >
                {/* {userOrders?.order_list} */}
                Your active order list is empty
              </Typography>{" "}
            </Grid>
          )}
        </Grid>
      </StyledOrderBox>
    </>
  );
};

export default MyOrderList;
