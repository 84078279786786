import React, { useEffect, useState } from "react";
import loginIcon from "../../assets/images/logohome.png";
import HomePng from "../../assets/images/suluhu-home.png";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Box,
  TextField,
} from "@mui/material";
import { ArrowDropDown, LocationOn, Search } from "@mui/icons-material";
import { CatagoryListAPI } from "../../api/JobBroadcast/CatagoryListAPI";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IoClose } from "react-icons/io5";
import { RiMapPinFill } from "react-icons/ri";
import MapHelper from "./MapHelper";
import { useUserAuth } from "../../context/UserAuthContext";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { getUser } from "../../utils/authUtils";
import { useNavigate, useLocation } from "react-router-dom";
import ResponsiveAppBar from "../../components/Layout/Header";
import { MdOutlineModeEdit } from "react-icons/md";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { MdDateRange } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import SuccessModal from "./SuccessModal";
import FailedMoadal from "./FailedMoadal";
import vectorImage from "../../assets/images/VectorImg1.png";
import Loader from "../../components/comman/Loader";

const JobBroadCastPage = () => {
  const navigate = useNavigate();
  //   const location1 = useLocation();
  //   const setteddata = location1.state || location1.search.get("data");
  //   const [isEdit ,setIsEdit] = useState(setteddata)
  const { user_id } = getUser();
  const [catagoryList, setCatagoryList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [subCatagoryList, setSubCatagoryList] = useState([]);
  const [finalCoordinates, setFinalCoordinates] = useState(null);
  const [displayInput, setdisplayInput] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formattedDate, setFormattedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");
  const [catagoryName, setCatagoryName] = useState("");
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [CustomDisable, setCustomDisable] = useState("button2");
  const [jobDetails, setJobDetails] = useState("");
  const [jobDate, setJobDate] = useState("");
  const [jobTime, setJobTime] = useState("");
  const [amount, setAmount] = useState("");
  const [IsEditable, setIsEditable] = useState(false);
  const [UserCatData, setUserCatData] = useState({});
  const [modal, setModal] = useState(UserCatData ? true : false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [failedPopupOpen, setFailedPopupOpen] = useState(false);
  const [firstPart, setFirstPart] = useState(true);
  const [dateModal, setDateModal] = useState(false);
  const [demoFlag, setDemoFlag] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isOptionalAddress, setIsOptionalAddress] = useState("");

  //   const [formData, setFormData] = useState({
  //  user_id: user_id,
  //       cat_id: activeCategory,
  //       sub_cat_id: selectedCategory.sub_id,
  //       job_detail: jobDetails,
  //       job_location: address,
  //       job_lat: finalCoordinates.lat,
  //       job_long: finalCoordinates.lng,
  //       job_dt: jobDate,
  //       job_tm: jobTime,
  //       job_budget: amount,
  //   });

  useEffect(() => {
    const isJobDetailsValid = jobDetails && jobDetails.trim() !== "";
    if (isJobDetailsValid && jobDate && jobTime && address && amount) {
      setCustomDisable("button1");
    } else {
      setCustomDisable("button2");
    }
  }, [jobDetails, jobDate, jobTime, address, amount]);

  const getCatagoryList = async () => {
    setIsLoading(true);
    try {
      const response = await CatagoryListAPI.getCatagoryList();
      const data = response.data;
      setCatagoryList(data?.categroy_list);
      let activeCat = data?.categroy_list[0].cat_id;
      let ActiveLogo = {
        category_name: data?.categroy_list[0].category_name,
        category_image: data?.categroy_list[0].category_image,
      };
      let subCatagoryList = data?.categroy_list[0].sub_category_list;
      let data1 = {
        category_name: data?.categroy_list[0].category_name,
        category_image: data?.categroy_list[0].category_image,
      };
      setCatagoryName(data1);
      setActiveCategory(activeCat);
      setSubCatagoryList(subCatagoryList);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCatagoryList();
  }, []);

  const handleSuccessPopupClose = () => {
    setSuccessPopupOpen(false);
    navigate("/broadcast-list");
  };

  const handleFailedPopupClose = () => {
    setFailedPopupOpen(false);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category.cat_id);
    let data = {
      category_name: category.category_name,
      category_image: category.category_image,
    };
    setCatagoryName(data);
    setSubCatagoryList(category.sub_category_list);
  };

  const handleCardClick = (category) => {
    setSelectedCategory(category);
    setModal(true);
  };
  const handleMapButtonClick = () => {
    setShowMap(true);
  };

  const toggle = () => {
    setModal(!modal);
  };
  const handleClose = () => {
    handelClear();
    localStorage.removeItem("UserCatData");
  };
  const handelClear = () => {
    setModal(false);
    setShowMap(false);
    setLocation(null);
    setFinalCoordinates(null);
    setAddress(null);
    setJobDetails("");
    setJobDate("");
    setJobTime("");
    setAmount("");
    setIsOptionalAddress('')
  };
  const [showMap, setShowMap] = useState(false);

  const HandleSubmit = async (e) => {
    e.preventDefault();

    const [year, month, day] = jobDate.split("-");

    // Formatting the date as dd-mm-yyyy
    const formattedDate = `${day}-${month}-${year}`;

    const [hours, minutes] = jobTime.split(":");

    let formattedTime;
    if (hours > 12) {
      formattedTime = `${hours - 12}:${minutes} PM`;
    } else if (hours === "00") {
      formattedTime = `12:${minutes} AM`;
    } else if (hours === "12") {
      formattedTime = `12:${minutes} PM`;
    } else {
      formattedTime = `${hours}:${minutes} AM`;
    }
    setFormattedTime(formattedTime);
    setFormattedDate(formattedDate);
    let UserCatDataSave = {
      catagoryName: catagoryName,
      selectedCategory: selectedCategory,
      user_id: user_id,
      cat_id: activeCategory,
      sub_cat_id: selectedCategory.sub_id,
      job_detail: jobDetails,
      job_location: address,
      job_lat: finalCoordinates.lat,
      job_long: finalCoordinates.lng,
      job_dt: jobDate,
      job_tm: jobTime,
      job_budget: amount,
      formattedDate: formattedDate,
      formattedTime: formattedTime,
    };
    localStorage.setItem("UserCatData", JSON.stringify(UserCatDataSave));
    localStorage.setItem("isPageEdit", "true");
    setIsEditable(true);
  };

  const handleAmountChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters except decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Check if the input is a non-negative number with optional decimal places
    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      setAmount(inputValue); // Update the state only if the input is valid
    }
  };

  const handelEdit = () => {
    setIsEditable(false);
    localStorage.removeItem("isPageEdit");
  };
  const handelReturn = () => {
    setIsEditable(false);
    localStorage.removeItem("UserCatData");
    localStorage.removeItem("isPageEdit");
    handelClear();
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem("UserCatData");
    const isPageEdit = localStorage.getItem("isPageEdit");

    if (savedFormData) {
      setIsEditable(isPageEdit === "true" ? true : false);

      const parsedData = JSON.parse(savedFormData);
      setUserCatData(parsedData);
      setModal(true);
      setSelectedCategory(parsedData.selectedCategory);

      setActiveCategory(parsedData.cat_id);
      setCatagoryName(parsedData.catagoryName);
      setJobDetails(parsedData.job_detail);
      setAddress(parsedData.job_location);
      setFinalCoordinates({
        lat: parsedData.job_lat,
        lng: parsedData.job_long,
      });
      setJobTime(parsedData.job_tm);
      setAmount(parsedData.job_budget);
      setJobDate(parsedData.job_dt);
      setFormattedDate(parsedData.formattedDate);
      setFormattedTime(parsedData.formattedTime);
    }
  }, []);

  const HaldelJobSubmit = async () => {
    localStorage.removeItem("UserCatData");
    localStorage.removeItem("isPageEdit");
    let UserCatDataSave = {
      user_id: user_id,
      cat_id: activeCategory,
      sub_cat_id: selectedCategory.sub_id,
      job_detail: jobDetails,
      job_location: isOptionalAddress
        ? `$${isOptionalAddress}#${address}`
        : address,
      job_lat: finalCoordinates.lat,
      job_long: finalCoordinates.lng,
      job_dt: jobDate,
      job_tm: jobTime,
      job_budget: amount,
    };

    try {
      const response = await CatagoryListAPI.postBroadcastJob(UserCatDataSave);
      let responseData = response.data;
      if (responseData.status === 200) {
        if (responseData.msg === "Job Broadcast Successfully")
          setSuccessPopupOpen(true);
        else {
          setMessage(responseData.msg);
          setFailedPopupOpen(true);
        }
      } else {
        setFailedPopupOpen(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
  const yyyy = today.getFullYear();

  const todayDate = `${yyyy}-${mm}-${dd}`;

  // Get date 5 days from todays
  const futureDate = new Date(today);
  futureDate.setDate(futureDate.getDate() + 5);
  const futureDd = String(futureDate.getDate()).padStart(2, "0");
  const futureMm = String(futureDate.getMonth() + 1).padStart(2, "0"); // January is 0
  const futureYyyy = futureDate.getFullYear();

  const maxDate = `${futureYyyy}-${futureMm}-${futureDd}`;

  if (isLoading) return <Loader />;
  return (
    <div>
      {!IsEditable && (
        <>
          <Box
            sx={{
              width: "100%",

              backgroundColor: "#F1F1F1",
              padding: "20px",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                marginLeft: "50px",
                color: "#0079EA",
                textAlign: "start",
              }}
            >
              Broadcast your job
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{
                marginLeft: "50px",
                color: "#000000",
                textAlign: "start",
              }}
            >
              Choose your quality work to earn your wealth on this great
              platform
            </Typography>
          </Box>

          <div
            className="mt-2"
            style={{
              marginLeft: "70px",
              marginRight: "40px",
              color: "#868686",
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <h6> What kind of service you are looking for ?</h6>
              </div>

              <div className="row mt-4 ">
                <div className="col-lg-4 ">
                  {catagoryList && catagoryList.length > 0 ? (
                    catagoryList.map((category) => (
                      <div
                        className={`card mb-2 ${
                          activeCategory === category.cat_id ? "active" : ""
                        }`}
                        key={category.cat_id}
                        onClick={() => handleCategoryClick(category)}
                        style={{
                          cursor: "pointer",
                          border:
                            activeCategory === category.cat_id
                              ? "2px solid #0079EA"
                              : "",
                          backgroundColor:
                            activeCategory === category.cat_id ? "#F1F8FF" : "",
                        }}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8 d-flex align-items-center">
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  margin: "0",
                                  marginLeft: "10px",
                                }}
                              >
                                {category.category_name}
                              </p>
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-end">
                              <img
                                src={category.category_image}
                                alt={category.category_name}
                                style={{ height: "40px", marginLeft: "10px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No categories available.</p>
                  )}
                </div>

                <div className="col-lg-8">
                  <div className="row">
                    {/* <div className="col-1"></div> */}
                    <div className="col-lg-11 offset-lg-1">
                      <div className="card ">
                        <div>
                          <h6 className="text-muted p-3">
                            Choose service category
                          </h6>
                        </div>
                        {subCatagoryList && subCatagoryList.length > 0 && (
                          <div className="sub-category-grid m-3">
                            {subCatagoryList.map((subCategory) => (
                              <div
                                className="sub-category-card"
                                key={subCategory.sub_id}
                                onClick={() => handleCardClick(subCategory)}
                              >
                                <img
                                  src={subCategory.sub_cat_image}
                                  alt={subCategory.sub_cat_name}
                                />
                                <p>{subCategory.sub_cat_name}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {selectedCategory && (
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      show={showModal}
                      centered={true}
                      zIndex={9999}
                      size="lg"
                      backdrop="static"
                      className="custom-modal"
                    >
                      <ModalHeader
                        className="d-flex justify-content-between align-items-center"
                        toggle={handleClose}
                      >
                        <div
                          className="d-flex align-items-center "
                          style={{ marginLeft: "40px" }}
                        >
                          <img
                            src={catagoryName.category_image}
                            alt="Suluhu Logo"
                            height="40"
                            style={{ height: "auto", width: "40px" }}
                            className="mr-2"
                          />
                          <div>
                            <h5
                              className="mb-0 d-flex flex-column "
                              style={{ marginLeft: "18px" }}
                            >
                              <span>{catagoryName.category_name}</span>
                              <div className="row">
                                <div className="col-1">
                                  <span>
                                    {" "}
                                    <img
                                      src={selectedCategory.sub_cat_image}
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                </div>
                                <div className="col-10">
                                  <span
                                    style={{
                                      color: "#0079EA",
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {selectedCategory.sub_cat_name}
                                  </span>
                                </div>
                              </div>
                            </h5>
                          </div>
                        </div>
                      </ModalHeader>

                      <ModalBody>
                        <div className="container">
                          {showMap ? (
                            <div className="map-container">
                              <MapHelper
                                setFinalCoordinates={setFinalCoordinates}
                                setShowMap={setShowMap}
                                setAddress={setAddress}
                                setFirstPart={setFirstPart}
                                setDateModal={setDateModal}
                                setDemoFlag={setDemoFlag}
                                setSelectedAddress={setSelectedAddress}
                              />
                            </div>
                          ) : (
                            <div className="container">
                              <form style={{ fontWeight: "500" }}>
                                <div className="mb-3">
                                  <label htmlFor="jobDetails" className="">
                                    Write us about your job requirement
                                  </label>
                                  <textarea
                                    className="form-control "
                                    style={{ color: "#868686" }}
                                    placeholder="About your job details"
                                    id="jobDetails"
                                    rows="3"
                                    value={jobDetails}
                                    onChange={(e) =>
                                      setJobDetails(e.target.value)
                                    }
                                  ></textarea>
                                </div>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label htmlFor="jobLocation">
                                      Select your job location
                                    </label>
                                  </div>
                                  {!finalCoordinates && (
                                    <button
                                      type="button"
                                      className="form-control"
                                      style={{
                                        backgroundColor: "#E8F3FD",
                                        color: "white",
                                      }}
                                      onClick={handleMapButtonClick}
                                    >
                                      <span style={{ color: "#0079EA" }}>
                                        <RiMapPinFill /> Map Location
                                      </span>
                                    </button>
                                  )}
                                  {address && (
                                    <div
                                      className="col-12"
                                      style={{ position: "relative" }}
                                    >
                                      <input
                                        className="form-control"
                                        value={address}
                                        style={{
                                          paddingRight: "30px",
                                          color: "#868686", // Add padding to make space for the icon
                                        }}
                                        readOnly // Make it read-only if you don't want it to be editable
                                      />
                                      <RiMapPinFill
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer", // Change cursor to pointer
                                          pointerEvents: "auto",
                                          color: "#ff6363", // Ensure the icon is clickable
                                        }}
                                        onClick={handleMapButtonClick} // Add onClick handler
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label htmlFor="jobLocation">
                                      Building no or name or street name
                                      (Optional)
                                    </label>
                                  </div>
                                  <TextField
                                    placeholder="Enter building no or name or street name (Optional)"
                                
                                    margin="dense"
                                    id="reason"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={isOptionalAddress}
                                    onChange={(e) =>
                                      setIsOptionalAddress(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-3">
                                  <div className="row">
                                    {" "}
                                    <div className="col-6">
                                      <label htmlFor="jobDate">Job date</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        style={{ color: "#868686" }}
                                        id="jobDate"
                                        value={jobDate}
                                        min={todayDate}
                                        max={maxDate}
                                        onChange={(e) =>
                                          setJobDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="col-6">
                                      <label htmlFor="jobTime">Job time</label>
                                      <input
                                        type="time"
                                        style={{ color: "#868686" }}
                                        className="form-control"
                                        id="jobTime"
                                        value={jobTime}
                                        onChange={(e) =>
                                          setJobTime(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <hr className=""></hr>
                                <div className="container">
                                  <div className="row d-flex">
                                    <div className="col-lg-8 d-flex flex-wrap align-items-center">
                                      <div className="p-1">
                                        <label
                                          htmlFor="amount"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "16px",
                                          }}
                                        >
                                          Enter Amount
                                        </label>
                                      </div>
                                      <div className="p-1 ">
                                        <input
                                          type="number"
                                          className="form-control no-arrows"
                                          id="amount"
                                          style={{
                                            fontSize: "24px",
                                            width: "130px",
                                            height: "50px",
                                            fontWeight: "600",
                                            lineHeight: "48px",
                                            textAlign: "right",
                                          }}
                                          value={amount}
                                          onChange={handleAmountChange}
                                        />
                                      </div>
                                      <div className="p-1">
                                        <label
                                          htmlFor="amount"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "16px",
                                          }}
                                        >
                                          (KES)
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                                      <button
                                        className={CustomDisable}
                                        disabled={CustomDisable === "button2"}
                                        onClick={HandleSubmit}
                                      >
                                        <span>Proceed</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {IsEditable && (
        <div className="">
          <div style={{ position: "relative" }}></div>
          <div
            style={{
              height: "35vh",
              background: "linear-gradient(90deg, #0079EA 0%, #004484 100%)",
              position: "relative",
            }}
          >
            <h1
              style={{
                position: "absolute",
                top: "15%",
                left: "10%",
                transform: "translate(-50%,-50%)",
                color: "white",
                zIndex: "2",
              }}
            >
              Service
            </h1>
            <button
              style={{
                position: "absolute",
                top: "15%",
                right: "10%",
                transform: "translate(-50%,-50%)",
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                zIndex: "2",
                fontSize: "20px",
              }}
              onClick={handelEdit}
            >
              <span
                className="mx-2"
                style={{ cursor: "pointer", fontSize: "20px" }}
              >
                {" "}
                <MdOutlineModeEdit />
              </span>
              Edit
            </button>
            <button
              style={{
                position: "absolute",
                top: "13%",
                right: "7%",
                transform: "translate(-50%,-50%)",
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                zIndex: "2",
                fontSize: "20px",
              }}
              onClick={handelReturn}
            >
              <span style={{ cursor: "pointer", fontSize: "35px" }}>
                {" "}
                <IoArrowBackCircleOutline />
              </span>
            </button>
          </div>

          {/* Next Page Section */}

          <div className="JobBroadCastBox">
            <div className="d-flex">
              <img
                src={catagoryName.category_image}
                alt="Suluhu Logo"
                height="60"
              />
              <h2 className="ms-3">{catagoryName.category_name}</h2>
            </div>
            <div className="d-flex offset-1">
              <img
                src={selectedCategory.sub_cat_image}
                alt="Suluhu Logo"
                height="25"
              />
              <h6 className="ms-2" style={{ color: "#0079EA" }}>
                {selectedCategory.sub_cat_name}
              </h6>
            </div>
            <hr></hr>
            <div className="container">
              <div className="d-flex   mb-2">
                <CgNotes
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "0px 10px 10px 0px ",
                    color: "rgb(68 68 68 / 64%)",
                  }}
                />
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Service
                  </div>
                  <p style={{ color: "#868686" }}>{jobDetails}</p>
                </div>
              </div>

              <div className="d-flex   mb-2">
                <RiMapPinFill
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "0px 10px 10px 0px",
                    color: "rgb(68 68 68 / 64%)",
                  }}
                />
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Address
                  </div>
                  <p
                    style={{ color: "#868686" }}
                  >{`${address}`}</p>
                </div>
              </div>
              {isOptionalAddress && ( <div className="d-flex   mb-2">
                <RiMapPinFill
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "0px 10px 10px 0px",
                    color: "rgb(68 68 68 / 64%)",
                  }}
                />
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                   Building no or name or street name
                  </div>
                  <p
                    style={{ color: "#868686" }}
                  >{`${isOptionalAddress}`}</p>
                </div>
              </div>)}
             

              <div className="row">
                <div className=" d-flex  col-md-4   ">
                  <div className="col-2 me-1">
                    <MdDateRange
                      style={{
                        height: "50px",
                        width: "50px",
                        color: "rgb(68 68 68 / 64%)",
                      }}
                    />
                  </div>

                  <div className="col-9">
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    Date
                    </div>
                    <p style={{ color: "#868686" }}>{formattedDate}</p>
                  </div>
                </div>
                <div className=" d-flex  col-md-4   ">
                  <div className="col-2 me-2">
                    <IoTimeOutline
                      style={{
                        height: "50px",
                        width: "50px",
                        color: "rgb(68 68 68 / 64%)",
                      }}
                    />
                  </div>

                  <div className="col-9">
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                      Time
                    </div>
                    <p style={{ color: "#868686" }}>{formattedTime}</p>
                  </div>
                </div>
                <div className=" d-flex  col-md-4   ">
                  <div className="col-2 me-2">
                    <img src={vectorImage} alt="Suluhu Logo" />
                  </div>

                  <div className="col-9">
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                      Service Amount
                    </div>
                    <p style={{ color: "#868686" }}>{`KES ${amount}`}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>

            <div className="d-flex align-items-center justify-content-center ">
              <button className={"buttonForSubmit"} onClick={HaldelJobSubmit}>
                <span>Broadcast your job</span>
              </button>
            </div>
          </div>
          <div style={{ position: "relative", height: "75vh" }}></div>
        </div>
      )}

      <SuccessModal
        jobDate={jobDate}
        formattedTime={formattedTime}
        open={successPopupOpen}
        handleClose={handleSuccessPopupClose}
      />

      <FailedMoadal
        open={failedPopupOpen}
        handleClose={handleFailedPopupClose}
        message={message ? message : "Failed to Broadcast your Job"}
      />
    </div>
  );
};

export default JobBroadCastPage;
