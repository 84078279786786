import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { MdOutlineCancel } from "react-icons/md";
import MapHelper from "../../pages/PostBroadCast/MapHelper";
const SignupFormAddressPopup = ({ open, close, setAddress }) => {
  const [SelectedAddress, setSelectedAddress] = useState();
  const [firstPart, setFirstPart] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [demoFlag, setDemoFlag] = useState(false);
  const [finalCoordinates, setFinalCoordinates] = useState("");

  return (
    <div>
      <Modal isOpen={open} centered style={{ position: "relative" }}>
        <ModalBody>
          <>
            <div className="d-flex justify-content-between m-1 p-1">
              <h5>Select Location</h5>
              <MdOutlineCancel
                onClick={close}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div className="map-container">
              <MapHelper
                setSelectedAddress={setSelectedAddress}
                setAddress={setAddress}
                setFinalCoordinates={setFinalCoordinates}
                setShowMap={setShowMap}
                setFirstPart={setFirstPart}
                setDateModal={close}
                setDemoFlag={setDemoFlag}
              />
            </div>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SignupFormAddressPopup;
