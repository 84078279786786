import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Avatar,
  styled,
} from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { motion } from "framer-motion";
import { notifyError } from "../../utils/notificationService";

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: 15,
    Padding: "1rem",
    textAlign: "center",
    position: "relative",
    overflow: "visible",
  },
});

export const CustomAvatar = styled(Avatar)({
  backgroundColor: "#2196f3",
  width: 100,
  height: 100,
  position: "absolute",
  top: "-4rem",
  left: "50%",
  transform: "translateX(-50%)",
  //   margin: "0 auto",
  // marginBottom: "16px",
});
const CustomDialogActions = styled(DialogActions)({
  justifyContent: "center",
});

const CustomButton = styled(Button)({
  backgroundColor: "#007bff",
  textTransform: "none",
  borderRadius: "2rem",
  padding: ".5rem 1rem",
  color: "white",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
});

export const CustomImageDialog = ({ open, onClose, children, ...props }) => {
  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="cancel-dialog-title"
      PaperProps={{
        component: motion.div,
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 0.5 },
      }}
    >
      {" "}
      {children}
    </CustomDialog>
  );
};

const CancelJobDialog = ({ open, onClose, Confirm }) => {
  const [reason, setReason] = useState("");

  const handleConfirm = () => {
    Confirm(reason);
    // if (reason?.trim()) {
    // } else {
    //   notifyError("Please provide a reason.");
    // }
  };

  return (
    <CustomImageDialog open={open} onClose={onClose}>
      <DialogTitle id="cancel-dialog-title" style={{ marginTop: "1.4rem" }}>
        <CustomAvatar>
          <PersonRemoveIcon style={{ fontSize: 70 }} />
        </CustomAvatar>
        Confirm!
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <DialogContentText style={{ marginTop: "0.7rem" }}>
          Are you sure you want to cancel this job?
        </DialogContentText>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton onClick={handleConfirm}>Yes</CustomButton>
        <CustomButton onClick={onClose}>No</CustomButton>
      </CustomDialogActions>{" "}
    </CustomImageDialog>
  );
};

export default CancelJobDialog;
