import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Box,
  IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import MotionCard from "../../components/MotionCard/MotionCard";
import { NavLink } from "react-router-dom";
const TopVenderCard = ({vendor ,user_id}) => (
  <NavLink
        to={{
          pathname: "/vender-details",
        }}
        state={{ provider_id: vendor.user_id, user_id: user_id ,DisabledQuantity : true}}
      >
  <MotionCard>
    <Card sx={{ mb: 2, borderRadius: 2, overflow: "hidden", boxShadow: 3 }}>
      <CardContent sx={{ display: "flex", p: 2 }}>
        {/* Vendor Image */}
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={vendor.profile_img} // If profile_img exists, use it; otherwise, render fallback
            alt={vendor.name}
            sx={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              
            }}
          ></Avatar>
        </Grid> .

        {/* Vendor Info */}
        <Grid item xs={9}>
          <CardContent sx={{ pl: 2 }}>
            {/* Name and Distance */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" fontWeight="bold" fontSize={"1rem"} align="left">
                {vendor.name}
              </Typography>
              <Box display="flex" alignItems="center">
                <LocationOnIcon color="primary" fontSize="small" />
                <Typography variant="body2" color="textSecondary">
                  {vendor.nearKm} km
                </Typography>
              </Box>
            </Box>

            {/* Services */}
            <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
              {vendor.services_list.map((service) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={service.sub_cat_id}
                  sx={{
                    backgroundColor: "#f0f4ff", // Light blue background color
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <Avatar
                    src={service.sub_cat_img}
                    alt={service.sub_cat_name}
                    sx={{ width: 24, height: 24, mr: 1 }}
                  />
                  <Typography variant="body2">
                    {service.sub_cat_name}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Ratings */}
            <Box display="flex" alignItems="center" mt={1}>
              <StarIcon sx={{ color: "#FFD700", fontSize: "large" }} />{" "}
              {/* Gold star color */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ ml: 0.5 }}
              >
                {vendor.ratings.toFixed(1)}
              </Typography>
            </Box>
          </CardContent>
        </Grid>
      </CardContent>
    </Card>
  </MotionCard>
  </NavLink>
);

export default TopVenderCard;

// const VendorList = ({ vendors }) => (
//   <Grid container spacing={2}>
//     {vendors.map((vendor) => (
//       <Grid item xs={12}  key={vendor.user_id}>
//         <TopVenderCard vendor={vendor} />
//       </Grid>
//     ))}
//   </Grid>
// );

// export default function App() {
//   const vendors_list = [
//     {
//       user_id: 100,
//       is_favourite: false,
//       username: "k1",
//       name: "Shivam",
//       profile_img: "https://api.suluhu.net/media/ProfileImages/Shivam_profile_image_FnAhCnw.jpg",
//       location: "bajaj nagar nagpur",
//       lat: 18.6317,
//       long: 73.7334,
//       ratings: 0.0,
//       nearKm: "0.0",
//       services_list: [
//         {
//           sub_cat_id: 1,
//           sub_cat_name: "Home Insurance",
//           sub_cat_img: "https://api.suluhu.net/media/SubCategoryImages/655dd40aa1d11_download.jpg"
//         },
//         {
//           sub_cat_id: 28,
//           sub_cat_name: "Moving",
//           sub_cat_img: "https://api.suluhu.net/media/SubCategoryImages/Moving.png"
//         }
//       ]
//     }
//   ];

//   return <VendorList vendors={vendors_list} />;
