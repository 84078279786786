import React, { useEffect, useState } from "react";
import withRouter from "../../components/comman/withRouter";
import { getUser } from "../../utils/authUtils";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendorDetails } from "../../features/Vendors/vendorDetailsSlice";
import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  Paper,
  Rating,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Button,
  ListItemAvatar,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CategoryButton from "./CategoryButton";

const FavouriteVendorDetail = ({ router }) => {
  const { params } = router;
  const { user_id } = getUser();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setSelectedSubCategory(0);
  };

  
  const { data, loading, error } = useSelector((state) => state.vendorDetails);
  const { profile_img, name, ratings, location, categories_list } = data ?? {};
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (isMounted) {
        await dispatch(
          fetchVendorDetails({
            user_id: user_id,
            vendor_id: params?.vendor_id,
          })
        );
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [user_id, params?.vendor_id]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <Container>
        <Box className="header">
          <Avatar className="avatar" src={profile_img} alt="James Anderson" />
          <Box className="user-info">
            <Typography variant="h6">{name}</Typography>
            <Typography color="primary">Electrical</Typography>
            <Rating name="read-only" value={ratings} readOnly size="small" />
            <Typography variant="body2" color="textSecondary">
              {location}
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Paper elevation={3} className="sidebar" sx={{ padding: 2 }}>
              <Tabs
                orientation="vertical"
                value={selectedCategory}
                onChange={handleCategoryChange}
                indicatorColor="primary"
                textColor="primary"
                className="tabs"
              >
                {categories_list?.map((category, index) => (
                  <Tab label={category.cat_name} key={index} />
                ))}
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Paper elevation={3} className="main-content">
              <Typography variant="h6">Choose service category</Typography>
              <Grid container spacing={2} className="category-buttons">
                {categories_list?.[selectedCategory]?.sub_cat_list?.map(
                  (subCategory, index) => (
                    <Grid item xs={4} key={index}>
                      <CategoryButton
                        icon={subCategory.sub_cat_img}
                        title={subCategory.sub_cat_name}
                        className="category-button"
                        selected={selectedCategory === index}
                        // onClick={() => setSelectedSubCategory(index)}
                      ></CategoryButton>
                    </Grid>
                  )
                )}
              </Grid>
              <List className="service-list">
                {categories_list?.[selectedCategory]?.sub_cat_list[
                  selectedSubCategory
                ]?.available_service?.map((service, index) => (
                  <ListItem key={index} className="service-item">
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={service.image}
                        alt={service.title}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={service.title}
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            {service.include_points.join(", ")}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            KES {service.amount}
                          </Typography>
                        </>
                      }
                    />
                    <Button variant="contained" startIcon={<AddIcon />}>
                      Add
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withRouter(FavouriteVendorDetail);
