import axios from "axios";
import { BASE_URL } from "../../config/config";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const ApproveServiceApis = {
  customerMarkAsCompleted: async (data) => {
    var axiosConfig = createAxiosConfig(
      "/customer_mark_as_completed",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },

  giveRatings: async (data) => {
    var axiosConfig = createAxiosConfig("/give_ratings", "POST", data);
    return await axios(axiosConfig);
  },
};
